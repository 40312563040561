<template>
	<div class="contant">
		<banner></banner>
		<div class="mainCon">
			<van-notice-bar left-icon="volume-o" style="margin-top: 10px;" scrollable text="重复播放不计入有效学习课时"/>
			<!-- 直播视频 -->
			<div class="zsjz_live">
				<div class="weiibo" v-if="data.type == 0">
					<img class="img" src="@/assets/img/pc/noreplay.jpg" />
				</div>
				<div class="weiibo" v-if="data.type == -1">
					<img class="img" src="@/assets/img/pc/end.jpg" />
				</div>
				<div id="ppt"></div>
				<div class="zsjz_live_right">
					<div id="player"></div>
					<div class="zsjz_live_con">
						<img src="@/assets/img/pc/live_logo.png" alt="">
					</div>
				</div>
			</div>
			<!-- 视频简介 -->
			<div class="live_t_con">
				<div class="titCon">
					<span class="state" v-if="data.type == 0" style="background: #FF0000;">课程未开始</span>
					<span class="state" v-if="data.type == 1" style="background: #2dd296;">直播中</span>
					<span class="state" v-if="data.type == 3">回放中</span>
					<span class="state" v-if="data.type == -1" style="background: #FF0000;">课程已结束</span>
					<span class="tit">{{ data.course_name }}</span>
				</div>
				<div class="btnCon">
					<div class="zjCon">
						<div class="zj">授课专家</div>
						<span class="name">{{data.speaker}}</span>
					</div>
					<div class="kjxz">
						<i class="el-icon-download"></i>
						<span class="txt" @click.stop="download(data)">课件下载</span>
					</div>
				</div>
				<div class="jianjie">{{ data.describe }}</div>
			</div>
			<!-- 课程推荐 -->
			<div class="letitMsg">
				<div class="titleCon">
					<span class="txt">课程推荐</span>
				</div>
				<img src="@/assets/img/pc/more.png" class="more" alt="">
			</div>
			<div class="videoCon">
				<videoItem :item="v" v-for="(v, index) in list" :key="v.course_id"  @click="toLive(v.type, v.course_id)"></videoItem>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import banner from "@/components/m/banner"
	import foot from "@/components/m/foot"
	import videoItem from "@/components/m/videoItem"
	import md5 from "js-md5";
	import tools from '@/utlis/tools.js';
	import {
		sendWebsocket,
		closeWebsocket,
		websocketSendMess
	} from '@/utlis/websocket.js'
	let liveSdk;
	export default {
		components: {
			banner,
			foot,
			videoItem
		},
		data() {
			return {
				id: 0,
				channelId: 0,
				userId: "e0ba569803",
				appId: "g06luf0rvw",
				appSecret: "e4a8375352fd4e7c9bf9df06d42d37e8",
				isPc: true,
				data: {},
				interval: null,
				// 课程推荐
				list:[]
			}
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					this.$router.go(0)
				},
				// 深度观察监听
				deep: true
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.requstWs();
			this.init();
			this.findRecommendList();
		},
		methods: {
			// ws连接成功，后台返回的ws数据，组件要拿数据渲染页面等操作
			wsMessage(msg) {
				console.log(msg.data)
				this.data.type = msg.data;
				// 直播尚未开始
				if (msg.data == 0) {
					this.$router.go(0);
				}
				// 直播中,回放
				if (this.data.type != 0) {
					this.loadPlayer();
				}
			},
			// ws连接失败，组件要执行的代码
			wsError() {
				console.log('ws连接失败')
			},
			// ws连接成功，组件要执行的代码
			succeed(e) {
				console.log('ws连接成功')
			},
			requstWs() {
				// 防止用户多次连续发送，所以要先关闭上次的ws请求。
				closeWebsocket()
				// 跟后端协商，需要什么参数数据给后台
				// 发起ws请求
				sendWebsocket(`${this.GLOBAL.socketUrl}`, {}, this.wsMessage, this.wsError,this.succeed)
			},
			async init() {
				this.isPc = tools.IsPC();
				const res = await this.$api.jntsInfo({
					course_id: this.id
				});
				// 直播信息
				const data = res.data;
				this.data = data;
				// 观看进度
				this.progress = data.progress ? data.progress : 0;
				// 房间号
				this.channelId = data.course_code ? data.course_code : 0;
				this.startTime();
				if (this.data.type != 0) {
					this.loadPlayer();
				}
			},
			loadPlayer() {
				let timestamp = new Date().getTime();
			
				liveSdk = new PolyvLiveSdk({
					channelId: this.channelId,
					sign: this.getSign(this.channelId, timestamp, this.appId), // 频道验证签名
					timestamp: timestamp, // 毫秒级时间戳
					appId: this.appId, // polyv 后台的appId
					user: {
						userId: this.userId,
						userName: 'tes',
						pic: '//livestatic.videocc.net/assets/wimages/missing_face.png'
					}
				});
			
				// 监听频道信息并初始化播放器
				liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
					liveSdk.setupPlayer({
						pptEl: '#ppt',
						el: '#player',
						type: 'auto',
						autoplay: false,
						pptPlaceholder: true,
						speed:false
					});
				})
			},
			// 频道验证签名
			getSign(channelId, timestamp, appId) {
				let params = {
					channelId: channelId,
					timestamp: timestamp,
					appId: appId
				}
				let sign = '';
				Object.keys(params).sort().map(key => {
					sign += key + params[key];
				})
				sign = this.appSecret + sign + this.appSecret;
				sign = md5(sign);
				sign = sign.toString(16);
				sign = sign.toUpperCase();
				return sign
			},
			// 记录观看时长
			async addHistory() {
				let params = {
					course_id: this.id
				}
				await this.$api.jntsAddHistory(params);
			},
			startTime() {
				this.interval = setInterval(() => {
					this.addHistory();
				}, 5 * 1000);
			},
			clearTime() {
				clearInterval(this.interval);
				this.interval = null;
			},
			// 下载课件
			download(item) {
				if (item.enclosure) {
					let a = document.createElement('a');
					a.href = item.enclosure;
					a.download = item.enclosure_name ? item.enclosure_name : "课件";
					a.target = "_blank";
					a.click();
				} else {
					this.$toast.fail("暂无课件")
				}
			},
			// 课程推荐
			async findRecommendList(){
				const res = await this.$api.findRecommendList({
					courseId: this.id
				});
				this.list = res.data;
			}
		},
		beforeRouteLeave(to, form, next) {
			this.clearTime();
			next();
		},
		beforeDestroy() {
			closeWebsocket()
			this.clearTime();
			this.$router.go(0)
		}
	}
</script>

<style scoped lang="less">
	.contant{
		width: 100%;
		background: url('../../assets/img/pc/bg.jpg') no-repeat;
		background-size: cover;
		.mainCon{
			width: 100%;
			padding: 0 .45rem;
			box-sizing: border-box;
			min-height: 4rem;
			// 直播视频
			.zsjz_live {
				position: relative;
				width: 100%;
				height: auto;
				flex-direction: column;
				margin-bottom: .3rem;
				display: flex;
				background-color:#fff;
				padding: .1rem;
				margin-top: .2rem;
				box-shadow: 0px 10px 7px 0px rgba(192,199,162,0.31);
				border-radius: .2rem;
				.weiibo{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 99999;
					.img{
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
				#ppt {
					width: 100%;
					height: 4rem;
					background-color: rgb(57, 56, 66);
				}
				.zsjz_live_right {
					flex-shrink: 0;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					overflow: hidden;
					#player {
						flex-shrink: 0;
						width: 100%;
						height: 4rem;
						background-color: #3f8cf6;
						margin-bottom: 5px;
						/deep/ .plv-live-player-container {
							min-height: auto !important;
						}
					}
					.zsjz_live_con {
						width: 100%;
						color: #FFFFFF;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						background-color: #5b7498;
						img {
							height: 100%;
						}
					}
				}
			}
			// 视频简介
			.live_t_con{
				width: 100%;
				background-color: #FFFFFF;
				padding: .25rem .35rem;
				box-sizing: border-box;
				box-shadow: 0px 10px 7px 0px rgba(192,199,162,0.31);
				border-radius: .1rem;
				margin-bottom: .4rem;
				.titCon {
					width: 100%;
					display: flex;
					align-items: center;				
					.state {
						padding:.06rem .2rem;
						background: #FCA106;
						border-radius: .1rem;
						color: #fff;
						font-size: .24rem;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-right: .28rem;
					}
				
					.tit {
						width: 3.8rem;
						font-size: .3rem;
						color: #656565;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
				
				.btnCon {
					width: 100%;
					display: flex;
					align-items: center;
					margin-top: .15rem;
					margin-bottom: .15rem;
				
					.zjCon {
						display: flex;
						align-items: center;
				
						.zj {
							padding:.06rem .2rem;
							background: #5B7565;
							border-radius: .1rem;
							font-size: .24rem;
							color: #FFFFFF;
							display: flex;
							justify-content: center;
							align-items: center;
						}
				
						.name {
							color: #656565;
							font-size: .28rem;
							margin-left: 14px;
						}
					}
				
					.kjxz {
						padding:.06rem .2rem;
						background: #856543;
						border-radius: .1rem;
						font-size: .24rem;
						color: #FFFFFF;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: .4rem;
						cursor: pointer;
					}
				}
				
				.jianjie {
					font-size: .26rem;
					color: #656565;
					line-height: .4rem;
					text-indent: 2em;
				}
			}
			// 课程推荐
			.letitMsg {
				width: 100%;
				margin-bottom: .35rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
			
				.titleCon {
					width: 6.2rem;
					height: .4rem;
					background: url('../../assets/img/pc/jb.png') no-repeat;
					background-size: 100% 100%;
					display: flex;
					align-items: center;
			
					.txt {
						font-size: .26rem;
						color: #fff;
						margin-left: .2rem;
					}
				}
			
				.more {
					width: .38rem;
					height: .38rem;
				}
			}
			// 课程列表
			.videoCon {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}
		}
	}
</style>